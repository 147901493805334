import atimg1 from '../images/team/img-1.jpg'
import atimg2 from '../images/team/img-2.jpg'
import atimg3 from '../images/team/img-3.jpg'
import atimg4 from '../images/team/img-4.jpg'


const Attorneys = [
    {
       Id:'1',
       AtImg:atimg1,
       name:'Jenelia Orkid',
       title:'Family Lawyer',     
    },
    {
       Id:'2',
       AtImg:atimg2,
       name:'David Harry',
       title:'Criminal Lawyer',     
    },
    {
       Id:'3',
       AtImg:atimg3,
       name:'Darothy Jane',
       title:'Business Lawyer',     
    },
    {
       Id:'4',
       AtImg:atimg4,
       name:'John Albart',
       title:'Real Estate Lawyer',     
    }
    
]

export default Attorneys;
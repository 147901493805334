import cimg1 from '../images/case/img-1.jpg'
import cimg2 from '../images/case/img-2.jpg'
import cimg3 from '../images/case/img-3.jpg'
import cimg4 from '../images/case/img-4.jpg'
import cimg5 from '../images/case/img-5.jpg'
import cimg6 from '../images/case/img-6.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'


const Cases = [
    {
        Id: '1',
        cTitle: 'Criminal Law', 
        cImg:cimg1,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        cTitle: 'Family Law', 
        cImg:cimg2,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        cTitle: 'Business Law', 
        cImg:cimg3,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        cTitle: 'Real Estate', 
        cImg:cimg4,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        cTitle: 'Personal Injury', 
        cImg:cimg5,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        cTitle: 'Family Law', 
        cImg:cimg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]

export default Cases;